<template>
  <v-container class="ma-0 pa-0">
    <hero title="About"></hero>
    <v-row>
      <v-col class="mt-10 ma-3 about-text">
        <p><strong>Lava for Good</strong> creates standout original content, podcasts, and experiences, amplifying the voices of contemporary social justice champions and inspiring action toward a more informed, empathic, and just society.
        </p>
        <p>
          Founded and led by renowned music executive, children's book author, and activist Jason Flom, Lava for Good’s #1-charting lineup of podcasts and the activism they inspire are credited with influencing exonerations, clemencies, pardons, legislation, and reforms nationwide. The lineup features Pulitzer, Emmy, and Webby Award-winning experts, attorneys, activists, and journalists on the front lines of human rights and justice movements.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="ma-3">
      <h2>Contact</h2>
        <ul style="list-style: none">
          <li><a href="mailto:info@signalco1.com">Sponsorship / Inquiries</a></li>
          <li><a href="mailto:dawn@thepresshouse.com">Media</a></li>
          <li><a href="https://innocencenetwork.org/"  target="_new">Legal Resources</a></li>
          <li><a href="https://forms.gle/LdNn1tTxRgKuCMpYA/"  target="_new">Click here</a> to submit a case for consideration on Wrongful Conviction.</li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'About',
  metaInfo: {
    title: 'About',
    meta: [
      { vmid: 'og:title', property: 'og:title', content: 'About'},
    ]
  },
}
</script>
<style lang="sass">
a
  text-decoration: none
.about-text
  font-size: 1.1rem
  color: #dcdcdc
</style>

